"use strict";
var baseWidth = 1920;
var baseHeight = document.querySelector(".main").offsetHeight;
var ratio = window.innerWidth / window.innerHeight;

function setScale() {

	$( '.main' ).css( {'margin-bottom': $( '.footer' ).height() + 'px'} );
	var currentWidth = document.querySelector("html").offsetWidth - getScrollbarWidth();
	var hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
	if (currentWidth > baseWidth) {
		console.log('currentWidth > baseWidth');
		var scale = (currentWidth + getScrollbarWidth()) / baseWidth;
		var body = document.querySelector(".main");
		var mainFH = document.querySelector(".main--fh");
		var wrapper = document.querySelector(".wrapper");
		var header = document.querySelector(".header");
		var ckModal = document.querySelector(".cmplz-cookiebanner");
		var footer = document.querySelector(".footer:not(.footer-inner)");
		body.style.transform = "scale(".concat(scale, ")");
		body.style["-moz-transform"] = "scale(".concat(scale, ")");
		body.style.transformOrigin = "left top";
		body.style.flex = "none";
		body.style["-moz-transform-origin"] = "left top";
		body.style.width = "".concat(baseWidth, "px");
		if (mainFH) {
			mainFH.style.minHeight = "calc(100vh / ".concat(scale, ")")
		}


		ckModal.style.transform = "scale(".concat(scale, ")");
		ckModal.style["-moz-transform"] = "scale(".concat(scale, ")");
		ckModal.style.transformOrigin = "right bottom";
		ckModal.style["-moz-transform-origin"] = "right bottom";


		header.style.transform = "scale(".concat(scale, ") translateX(-50%)");
		header.style["-moz-transform"] = "scale(".concat(scale, ") translateX(-50%)");
		header.style.transformOrigin = "left top";
		header.style.flex = "none";
		header.style.top = 20 * scale + "px";
		header.style["-moz-transform-origin"] = "left top";
		header.style.width = "".concat(baseWidth, "px");
		if (footer) {
			footer.style.transform = "scale(".concat(scale, ") translateX(-50%)");
			footer.style["-moz-transform"] = "scale(".concat(scale, ") translateX(-50%)");
			footer.style.transformOrigin = "left bottom";
			footer.style.margin = "0 0 ".concat(10 * scale, "px");
			footer.style.flex = "none";
			footer.style["-moz-transform-origin"] = "left top";
			footer.style.width = "".concat(baseWidth - 20, "px");
			footer.style.position = "absolute";
			footer.style.bottom = "0px";
			body.style.marginBottom = "".concat(footer.offsetHeight * scale, "px");
			setTimeout(function () {
				wrapper.style.height = "".concat(document.querySelector(".main").offsetHeight * scale + document.querySelector(".footer").offsetHeight * scale, "px")
				var currentdate = new Date();
				var datetime = "Last Sync: " + currentdate.getDate() + "/"
					+ (currentdate.getMonth()+1)  + "/"
					+ currentdate.getFullYear() + " @ "
					+ currentdate.getHours() + ":"
					+ currentdate.getMinutes() + ":"
					+ currentdate.getSeconds();
				console.log('asd', datetime, wrapper.style.height);
			}, 2000)
		} else {
			setTimeout(function () {
				wrapper.style.height = "".concat(document.querySelector(".main").offsetHeight * scale, "px")
				console.log('qwe');
			}, 1200)
		}
		document.querySelectorAll(".banner-inner").forEach(function (element) {
			element.style.height = "calc((100vh - 20px) / ".concat(scale, ")")
		});
		document.querySelectorAll(".banner-inner video").forEach(function (element) {
			element.style.minHeight = "calc((100vh - 20px) / ".concat(scale, ")")
		});
		document.querySelectorAll(".half-img").forEach(function (element) {
			element.style.height = "calc((100vh - 20px) / ".concat(scale, ")")
		});
		document.querySelectorAll(".half-img video").forEach(function (element) {
			element.style.minHeight = "calc((100vh - 20px) / ".concat(scale, ")")
		});
		document.querySelectorAll(".services-box").forEach(function (element) {
			element.style.maxHeight = "calc((100vh - 20px) / ".concat(scale, ")")
		});// updateHeaderPosition();
	} else {
		resetScale()
		console.log('currentWidth < baseWidth');
	}
}

function updateHeaderPosition() {
	var header = document.querySelector(".header");
	if (header) {
		var scrollY = window.scrollY;
		var scale = parseFloat(document.querySelector(".main").style.transform.match(/scale\((\d+(\.\d+)?)\)/)[1]);
		header.style.transform = "translateX(-50%) translateY(".concat(scrollY / scale, "px)");
		header.style.transition = "none"
	}
}

function resetScale() {
	document.querySelector(".cmplz-cookiebanner").style.transform = "none";
	document.querySelector(".cmplz-cookiebanner").style["-moz-transform"] = "none";

	document.querySelector(".main").style.transform = "none";
	document.querySelector(".main").style["-moz-transform"] = "none";
	document.querySelector(".main").style.width = "auto";
	document.querySelector(".main").style.height = "auto";
	document.querySelector(".header").style.transform = "translateX(-50%)";
	document.querySelector(".header").style["-moz-transform"] = "translateX(-50%)";
	document.querySelector(".header").style.width = "100%";
	document.querySelector(".header").style.height = "auto";
	document.querySelector(".wrapper").style.height = "auto";
	if (document.querySelector(".main--fh")) {
		document.querySelector(".main--fh").style.minHeight = "0"
	}
	if (document.querySelector(".footer:not(.footer-inner)")) {
		document.querySelector(".footer:not(.footer-inner)").style.transform = "translateX(-50%)";
		document.querySelector(".footer:not(.footer-inner)").style["-moz-transform"] = "translateX(-50%)";
		document.querySelector(".footer:not(.footer-inner)").style.width = "calc(100% - 20px)";
		document.querySelector(".footer:not(.footer-inner)").style.height = "auto";
		document.querySelector(".footer").style.position = "static"
	}
	document.querySelectorAll(".banner-inner").forEach(function (element) {
		element.removeAttribute("style")
	});
	document.querySelectorAll(".banner-inner video").forEach(function (element) {
		element.removeAttribute("style")
	});
	document.querySelectorAll(".half-img").forEach(function (element) {
		element.removeAttribute("style")
	});
	document.querySelectorAll(".half-img video").forEach(function (element) {
		element.removeAttribute("style")
	});
	document.querySelectorAll(".services-box").forEach(function (element) {
		element.removeAttribute("style")
	});
	document.querySelectorAll(".header").forEach(function (element) {
		element.removeAttribute("style")
	})
}

function getScrollbarWidth() {
	var scrollDiv = document.createElement("div");
	scrollDiv.style.width = "100px";
	scrollDiv.style.height = "100px";
	scrollDiv.style.overflow = "scroll";
	scrollDiv.style.position = "absolute";
	scrollDiv.style.top = "-9999px";
	document.body.appendChild(scrollDiv);
	var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
	document.body.removeChild(scrollDiv);
	return scrollbarWidth
}

setScale();
window.addEventListener("resize", setScale);
window.addEventListener("resize", function () {
	setScale()
})
